<template>
  <article v-if="isExistsItem() === true" class="iv2-banner">
    <div class="container-fluid banners">
      <div class="row clearfix">
        <div class="column full">
          <hr class="m-0" />
          <div class="spacer height-60"></div>
        </div>
      </div>
      <div class="row clearfix">
        <div
          v-for="item in banner.items"
          :key="item.id"
          :class="listLayout(banner.setting.type)"
        >
          <a
            rel="noopener"
            :href="item.link_href"
            :target="setTargetAttr(item.is_external)"
            @click="clickEventTrigger(item.analytics_event_name)"
          >
            <picture>
              <source
                type="image/webp"
                :srcset="
                  item.image + $store.state.publicLang.general.image_webp
                "
              />
              <img class="m-0" :src="item.image" :alt="item.alternative_text" />
            </picture>
          </a>
        </div>
      </div>
      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-60"></div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    banner: Object,
  },

  data() {
    return {
      query: "",
      lang: "",
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    listLayout(type) {
      let col = "col-md-4 col-xs-12 mb-3";
      switch (type) {
        case "468x60":
          col = "col-md-6 col-xs-12 mb-3";
          break;

        case "980x120":
          col = "col-xs-12 mb-3";
          break;
      }
      return col;
    },

    setTargetAttr(flag) {
      return flag === 1 ? "_blank" : "_self";
    },

    isExistsItem() {
      return this.banner.items.length > 0 ? true : false;
    },

    clickEventTrigger(event) {
      return this.$gtag != undefined &&
        event != undefined &&
        event != "" &&
        event != null
        ? this.$gtag.event("click", {
            event_category: "Banner",
            event_label: event,
            value: 1,
          })
        : false;
    },
  },
};
</script>

<style lang="scss"></style>
