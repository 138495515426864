import initialsRepository from "./initialsRepository";
import settingsRepository from "./settingsRepository";
import languageRepository from "./languageRepository";
import routesRepository from "./routesRepository";
import topPageRepository from "./topPageRepository";
import pagesRepository from "./pagesRepository";
import freepagesRepository from "./freepagesRepository";
import newsRepository from "./newsRepository";
import contactsRepository from "./contactsRepository";
import couponsRepository from "./couponsRepository";
import downloadsRepository from "./downloadsRepository";
import enquetesRepository from "./enquetesRepository";

const repositories = {
  initials: initialsRepository,
  settings: settingsRepository,
  language: languageRepository,
  routes: routesRepository,
  topPage: topPageRepository,
  pages: pagesRepository,
  freepages: freepagesRepository,
  news: newsRepository,
  contacts: contactsRepository,
  coupons: couponsRepository,
  downloads: downloadsRepository,
  enquetes: enquetesRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
