import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index(page) {
    const lang = store.state.language;
    return Repository.get(`/coupons`, {
      params: {
        hl: lang,
        page: page,
      },
    });
  },

  show(id, preview, token) {
    const lang = store.state.language;
    if (preview == "preview" && token) {
      return Repository.get(`/preview/coupons/${id}/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hl: lang,
        },
      });
    } else {
      return Repository.get(`/coupons/${id}/show`, {
        params: {
          hl: lang,
        },
      });
    }
  },
};
