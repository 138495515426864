export default {
  created() {
    if (
      this.$route.query.mode == "preview" &&
      this.$route.query.api_token &&
      !this.$cookies.get("preview-token")
    ) {
      this.setCookie();
    }
  },

  methods: {
    setCookie() {
      this.$cookies.set("preview-token", this.$route.query.api_token);
    },
  },
};
