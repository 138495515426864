import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index(page) {
    const lang = store.state.language;
    return Repository.get(`/downloads`, {
      params: {
        hl: lang,
        page: page,
      },
    });
  },
};
