import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index(preview, token) {
    const lang = store.state.language;
    if (preview == "preview" && token) {
      return Repository.get(`/preview/top_page`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hl: lang,
        },
      });
    } else {
      return Repository.get(`/top_page`, {
        params: {
          hl: lang,
        },
      });
    }
  },
};
