import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index() {
    const lang = store.state.language;
    return Repository.get(`/settings`, {
      params: {
        hl: lang,
      },
    });
  },
};
