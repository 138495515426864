import Repository from "./repository";
import store from "@/store/index.js";

export default {
  show(segment, preview, token) {
    const lang = store.state.language;
    if (preview == "preview" && token) {
      return Repository.get(`/preview/pages${segment}/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hl: lang,
        },
      });
    } else {
      return Repository.get(`/pages${segment}/show`, {
        params: {
          hl: lang,
        },
      });
    }
  },
};
