<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="news.open_type === 1 && indexOpenType === 1">
        <BaseTitle :items="items" />
        <BaseBreadcrumb :items="breadcrumb" />
        <LayoutDetail :news="news" :category-id="prevId" :page="prevPage" />
      </div>

      <Error404 v-else-if="news.open_type === 0 || indexOpenType === 0" />
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutDetail from "./components/LayoutDetail.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseBreadcrumb,
    BaseTitle,
    LayoutDetail,
    BaseLoading,
    Error404,
  },

  mixins: [Meta, Preview],

  data() {
    return {
      news: {},
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      breadcrumb: [
        {
          title: "",
          segment_name: "news",
        },
        {
          title: "",
          segment_name: "newsDetail",
        },
      ],
      indexOpenType: "",
      pageActive: false,
      loading: true,
      prevId: null,
      prevPage: null,
      params: {
        type: "news",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "news") {
        vm.prevId = from.query.category_id;
        vm.prevPage = from.query.page;
      }
    });
  },

  mounted() {
    const newsRepository = this.$repository.get("news");
    const news = newsRepository.index(this.params);
    const self = this;
    news
      .then((result) => {
        const res = result.data.response;
        self.items.title = res.title;
        self.items.subtitle = res.subtitle;
        self.breadcrumb[0].title = res.title;
        if (self.$route.query.mode == "preview") {
          self.indexOpenType = 1;
        } else {
          self.indexOpenType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
        self.indexOpenType = 0;
      });

    const id = this.$route.params.id;
    const newsDetail = newsRepository.show(
      id,
      this.$route.query.mode,
      this.$route.query.api_token
    );
    newsDetail
      .then((result) => {
        self.news = result.data.response;
        self.breadcrumb[1].title = self.news.title;
        if (self.$route.query.mode == "preview") {
          self.news.open_type = 1;
        }
      })
      .catch((error) => {
        console.error(error);
        self.indexOpenType = 0;
      })
      .finally(() => {
        self.loading = false;
        self.pageActive = true;
      });
  },
};
</script>

<style lang="scss"></style>
