<template>
  <article class="iv2-article">
    <transition mode="out-in" :name="animation" appear>
      <div v-if="pageActive">
        <Error404 v-if="openType === 0" />

        <div v-else-if="openType === 1">
          <BaseTitle :items="items" />
          <BaseBreadcrumb :items="items.breadcrumb" />
          <FreepageIndex
            v-if="indexType === 'top'"
            :parentItems="items"
            :items="items.children"
          />
          <LayoutPattern :items="items" />
          <FreepageIndex
            v-if="indexType === 'bottom'"
            :parentItems="items"
            :items="items.children"
          />
        </div>
      </div>
    </transition>

    <div v-if="loading">
      <BaseLoading />
    </div>
  </article>
</template>

<script>
import BaseTitle from "@/components/items/BaseTitle.vue";
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import FreepageIndex from "./components/FreepageIndex.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Hash from "@/mixins/AnchorLink";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseTitle,
    BaseBreadcrumb,
    LayoutPattern,
    FreepageIndex,
    BaseLoading,
    Error404,
  },

  mixins: [Meta, Hash, Preview],

  props: {
    animation: String,
  },

  data() {
    return {
      items: {},
      openType: "",
      indexType: "",
      loading: true,
      pageActive: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.loading = true;
        this.pageActive = false;
        this.getPages();
      }
    },
  },

  mounted() {
    this.getPages();
  },

  methods: {
    getPages() {
      const path = this.$route.path;
      const pagesRepository = this.$repository.get("pages");
      const pages = pagesRepository.show(
        path,
        this.$route.query.mode,
        this.$route.query.api_token
      );
      const self = this;
      pages
        .then((result) => {
          self.items = result.data.response;
          self.indexType = self.items.index_type;
          if (self.$route.query.mode == "preview") {
            self.openType = 1;
          } else {
            self.openType = self.items.open_type;
          }
        })
        .catch((error) => {
          console.error(error);
          self.openType = 0;
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
