<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="indexOpenType === 1">
        <BaseTitle :items="items" />
        <BaseBreadcrumb :items="breadcrumb" />

        <!-- 記事一覧 -->
        <LayoutList
          :news="news"
          :categories="categories"
          :archives="archives"
          :explanation="explanation"
          :type="type"
          @click-change="clickChange"
          @select-change="selectChange"
          @select-archive="selectArchive"
        />

        <!-- ページネーション -->
        <BasePagination
          v-if="news.length > 0"
          :last-page="lastPage"
          :sort-page="params.page"
          @pagination="pagination"
        />
      </div>

      <Error404 v-else-if="indexOpenType === 0" />
    </article>

    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutList from "./components/LayoutList.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import BasePagination from "@/components/items/BasePagination.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";

export default {
  components: {
    BaseBreadcrumb,
    BaseTitle,
    LayoutList,
    BaseLoading,
    BasePagination,
    Error404,
  },

  mixins: [Meta],

  data() {
    return {
      news: [],
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      breadcrumb: [
        {
          title: "",
          segment_name: "news",
        },
      ],
      categories: [],
      archives: [],
      lastPage: 1,
      explanation: "",
      type: "",
      indexOpenType: "",
      pageActive: false,
      loading: true,
      params: {
        type: "news",
        limit: "",
        page: 1,
        category_id: 0,
        year: 0,
      },
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.getNews();
      }
    },
  },

  created() {
    const newsRepository = this.$repository.get("news");
    const categories = newsRepository.categories();
    const self = this;
    categories.then((result) => {
      self.categories = result.data.response;
    });

    let year = 2020;
    let nowDate = new Date().getFullYear();
    let tmp = nowDate - year + 1;
    for (let i = 0; i < tmp; i++) {
      this.archives.push(nowDate - i);
    }
  },

  mounted() {
    this.getNews();
  },

  methods: {
    getNews() {
      this.$route.query.page
        ? (this.params.page = Number(this.$route.query.page))
        : (this.params.page = 1);
      this.$route.query.category_id
        ? (this.params.category_id = Number(this.$route.query.category_id))
        : (this.params.category_id = 0);
      this.$route.query.year
        ? (this.params.year = Number(this.$route.query.year))
        : (this.params.year = 0);

      const newsRepository = this.$repository.get("news");
      const news = newsRepository.index(this.params);
      const self = this;
      news
        .then((result) => {
          const res = result.data.response;
          self.news = res.data;
          self.items.title = res.title;
          self.items.subtitle = res.subtitle;
          self.lastPage = res.last_page;
          self.explanation = res.explanation;
          self.type = res.layout_type;
          self.breadcrumb[0].title = res.title;
          if (self.$route.query.mode == "preview") {
            self.indexOpenType = 1;
          } else {
            self.indexOpenType = res.open_type;
          }
        })
        .catch(() => {
          self.indexOpenType = 0;
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
          window.scrollTo({
            top: 0,
          });
        });
    },

    pagination(pageNum) {
      this.loading = true;
      if (pageNum >= 1 && pageNum <= this.lastPage) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { page: pageNum }),
        });
      }
    },

    clickChange(id) {
      this.loading = true;
      if (id > 0) {
        this.$router.push({
          query: {
            category_id: id,
            year: this.$route.query.year ? this.$route.query.year : 0,
          },
        });
      } else {
        this.$router.push({ name: "news" });
      }
      this.params.page = 1;
    },

    selectChange(event) {
      this.loading = true;
      if (event.target.value > 0) {
        this.$router.push({
          query: {
            category_id: event.target.value,
            year: this.$route.query.year ? this.$route.query.year : 0,
          },
        });
      } else {
        this.$router.push({ name: "news" });
      }
      this.params.page = 1;
    },

    selectArchive(event) {
      this.loading = true;

      if (event.target.value === this.$route.query.year) {
        this.$route.query.year = null;
      }

      if (event.target.value > 0) {
        this.$router.push({
          query: {
            category_id: this.$route.query.category_id
              ? this.$route.query.category_id
              : 0,
            year: event.target.value,
          },
        });
      } else {
        this.$router.push({ name: "news" });
      }
      this.params.page = 1;
    },
  },
};
</script>

<style lang="scss"></style>
