<template>
  <div class="iv2-favorite">
    <button
      @click="sendFavorite"
      :class="setAttr.btnActive"
      class="iv2-favorite__btn"
      type="button"
    >
      <i class="fa fa-star" aria-hidden="true"></i>
      {{ $store.state.publicLang.general.favorite_btn }}
    </button>
    <span class="iv2-favorite__balloon theme__favorite-balloon">
      {{ items.favorite }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    items: Object,
  },

  data() {
    return {
      params: {
        client_id: "",
      },
      favoritePrefix: "iv-favorite-",
      setAttr: {
        btnActive: "",
      },
    };
  },

  mounted() {
    this.switchBtnStatus();
  },

  methods: {
    sendFavorite() {
      this.params.client_id = this.getClientKey();

      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.favorite(this.items.id, this.params);
      const self = this;
      const favoriteKey = self.favoritePrefix + self.items.id;

      blog
        .then((result) => {
          // cookie保存
          if (self.isFavorited() == false) {
            self.$cookies.set(favoriteKey, result.data.client_id, "44640min");
          } else {
            self.$cookies.remove(favoriteKey);
          }
          self.switchBtnStatus();
          self.$emit("update");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getClientKey() {
      const result = this.$cookies.get(this.favoritePrefix + this.items.id);
      return result != null ? result : "";
    },

    isFavorited() {
      return this.getClientKey() != "";
    },

    switchBtnStatus() {
      if (this.isFavorited()) {
        this.$set(this.setAttr, "btnActive", "active");
      } else {
        this.$set(this.setAttr, "btnActive", "");
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-favorite {
  .iv2-favorite__btn {
    margin: 0 15px 0 0;
    padding: 8px 20px;
    background: none;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    color: #909090;
    font-weight: bold;
    font-size: 18px;

    &:hover {
      background: #f0f0f0;
    }

    &.active {
      color: #101010;
      background: #efece5;

      i {
        color: #ff9612;
      }
    }
  }

  .iv2-favorite__balloon {
    position: relative;
    padding: 8px 20px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;

    &::after {
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      border-width: 10px;
      margin-top: -10px;
      right: 100%;
      top: 50%;
    }
  }
}
</style>
