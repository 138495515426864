import Repository from "./repository";
import store from "@/store/index.js";

export default {
  show() {
    const lang = store.state.language;
    return Repository.get(`/enquetes`, {
      params: {
        hl: lang,
      },
    });
  },

  send(fields) {
    const type = process.env.VUE_APP_ENQUETE;
    return Repository.post(`/enquetes/${type}`, fields, {
      withCredentials: true,
      timeout: 30000,
    });
  },
};
