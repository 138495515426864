<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="coupon.open_type === 1 && indexOpenType === 1">
        <BaseTitle :items="items" />
        <LayoutPattern :coupon="coupon" />
        <LayoutModal :coupon="coupon" />
      </div>

      <Error404
        v-else-if="
          coupon.open_type === 0 || indexOpenType === 0 || pageDisplay === 0
        "
      />
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import LayoutModal from "./components/LayoutModal.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseTitle,
    LayoutPattern,
    LayoutModal,
    BaseLoading,
    Error404,
  },

  mixins: [Meta, Preview],

  data() {
    return {
      coupon: {},
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      indexOpenType: "",
      pageDisplay: "",
      pageActive: false,
      loading: true,
    };
  },

  mounted() {
    const couponsRepository = this.$repository.get("coupons");
    const coupons = couponsRepository.index();
    const self = this;
    coupons
      .then((result) => {
        const res = result.data.response;
        self.items.title = res.title;
        self.items.subtitle = res.subtitle;
        if (self.$route.query.mode == "preview") {
          self.indexOpenType = 1;
        } else {
          self.indexOpenType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
        self.indexOpenType = 0;
      });

    const id = this.$route.params.id;
    const couponDetail = couponsRepository.show(
      id,
      this.$route.query.mode,
      this.$route.query.api_token
    );
    couponDetail
      .then((result) => {
        self.coupon = result.data.response;
        if (self.$route.query.mode == "preview") {
          self.coupon.open_type = 1;
        }
      })
      .catch((error) => {
        console.error(error);
        self.pageDisplay = 0;
      })
      .finally(() => {
        self.loading = false;
        self.pageActive = true;
      });
  },
};
</script>

<style lang="scss"></style>
