import Repository from "./repository";
import store from "@/store/index.js";

export default {
  show() {
    const lang = store.state.language;
    return Repository.get(`/contacts`, {
      params: {
        hl: lang,
      },
    });
  },

  basic(fields) {
    const type = process.env.VUE_APP_CONTACT_GEN;
    return Repository.post(`/contacts/${type}`, fields, {
      withCredentials: true,
      timeout: 30000,
    });
  },

  business(fields) {
    const type = process.env.VUE_APP_CONTACT_BIZ;
    return Repository.post(`/contacts/${type}`, fields, {
      withCredentials: true,
      timeout: 30000,
    });
  },

  customer(fields) {
    const type = process.env.VUE_APP_CONTACT_CUS;
    return Repository.post(`/contacts/${type}`, fields, {
      withCredentials: true,
      timeout: 30000,
    });
  },
};
