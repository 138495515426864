import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: "",
    ogpImage: null,
    publicLang: {},
    themeLang: {},
  },

  mutations: {
    setLanguage(state, { lang }) {
      state.language = lang;
    },

    setPublicLang(state, { obj }) {
      state.publicLang = obj;
    },

    setThemeLang(state, { obj }) {
      state.themeLang = obj;
    },

    setOgpImage(state, { img }) {
      state.ogpImage = img;
    },
  },

  actions: {
    setLanguage({ commit }, { lang }) {
      commit("setLanguage", { lang: lang });
    },

    setPublicLang({ commit }, { obj }) {
      commit("setPublicLang", { obj: obj });
    },

    setThemeLang({ commit }, { obj }) {
      commit("setThemeLang", { obj: obj });
    },

    setOgpImage({ commit }, { img }) {
      commit("setOgpImage", { img: img });
    },
  },

  modules: {},
});
