<template>
  <transition name="fade-up" appear>
    <div
      v-if="show"
      :class="{ closed: scrollY > 10, active: activeHover }"
      @mouseover="fadeIn"
      @mouseleave="fadeOut"
      class="iv2-preview d-none d-xl-block"
    >
      <div class="iv2-preview__block rounded-top">
        <div class="iv2-preview__text text-muted">
          <h5 class="size-21 text-center">
            <i class="fas fa-eye mr-2"></i
            >{{ $store.state.publicLang.general.preview_title }}
          </h5>
          <div class="iv2-preview__summary">
            <span>{{ $store.state.publicLang.general.preview_attention }}</span>
            <p v-if="token" class="size-16" style="line-height: 1.5">
              {{ $store.state.publicLang.general.preview_summary }}
            </p>
            <div v-if="token" class="text-right mt-3">
              <a :href="editorLink" class="iv2-editor__btn btn">{{
                $store.state.publicLang.general.preview_btn
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0,
      show: false,
      activeHover: false,
      editorLink: "",
      token: "",
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.show = true;
    this.editorLink = this.$route.query.locale;

    if (this.$route.query.api_token) {
      this.token = true;
    } else {
      this.token = false;
    }
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    fadeIn() {
      this.activeHover = true;
    },

    fadeOut() {
      this.activeHover = false;
    },
  },
};
</script>

<style lang="scss">
.iv2-preview {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 111111;
  transition: 0.3s;

  &.closed {
    opacity: 0.2;

    .iv2-preview__summary {
      max-height: 0;
      opacity: 0;
    }

    &.active {
      opacity: 1;

      .iv2-preview__summary {
        max-height: 200px;
        opacity: 1;
      }
    }
  }

  .iv2-preview__block {
    background-color: rgba(255, 255, 255, 0.9);

    .iv2-preview__text {
      padding: 15px 30px;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 1.5;

      .iv2-editor__btn {
        background-color: #fee12d;
      }
    }
  }

  .iv2-preview__summary {
    transition: 0.5s;
    max-height: 200px;
    font-family: initial;
  }
}

.fade-up-enter {
  -webkit-transform: translate(-50%, 100px);
  transform: translate(-50%, 100px);
  opacity: 0;
}

.fade-up-enter-to {
  opacity: 1;
}

.fade-up-enter-active {
  -webkit-transition: 3s;
  transition: 3s;
}
</style>
