<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="indexOpenType === 1">
        <BaseTitle :items="items" />

        <!-- ダウンロード一覧 -->
        <LayoutList :download="download" :explanation="explanation" />

        <!-- ページネーション -->
        <BasePagination
          v-if="download.length > 0"
          :last-page="lastPage"
          @pagination="pagination"
        />
      </div>

      <Error404 v-else-if="indexOpenType === 0" />
    </article>

    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutList from "./components/LayoutList.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import BasePagination from "@/components/items/BasePagination.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseTitle,
    LayoutList,
    BaseLoading,
    BasePagination,
    Error404,
  },

  mixins: [Meta, Preview],

  data() {
    return {
      download: [],
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      lastPage: 1,
      sortPage: "",
      explanation: "",
      indexOpenType: "",
      pageActive: false,
      loading: true,
    };
  },

  mounted() {
    this.getDownloads();
  },

  methods: {
    getDownloads() {
      const downloadsRepository = this.$repository.get("downloads");
      const downloads = downloadsRepository.index(this.sortPage);
      const self = this;
      downloads
        .then((result) => {
          const res = result.data.response;
          self.download = res.data;
          self.items.title = res.title;
          self.items.subtitle = res.subtitle;
          self.lastPage = res.last_page;
          self.explanation = res.explanation;
          if (self.$route.query.mode == "preview") {
            self.indexOpenType = 1;
          } else {
            self.indexOpenType = res.open_type;
          }
        })
        .catch((error) => {
          console.error(error);
          self.indexOpenType = 0;
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
          window.scrollTo({
            top: 0,
          });
        });
    },

    pagination(page) {
      this.loading = true;
      if (page >= 1 && page <= this.lastPage) {
        this.sortPage = page;
        this.getDownloads();
      }
    },
  },
};
</script>

<style lang="scss"></style>
