<template>
  <div :class="article">
    <div class="container">
      <div class="iv2-error-404">
        <div class="iv2-error-404-block">
          <h1 class="iv2-error-404-number">
            {{ $store.state.publicLang.system.page404.status }}
          </h1>
          <h2 class="iv2-error-404-lead">
            {{ $store.state.publicLang.system.page404.lead }}
          </h2>
          <p class="iv2-error-404-summary text-pre">
            {{ $store.state.publicLang.system.page404.summary }}
          </p>
          <div class="row clearfix">
            <div class="column full text-center">
              <BaseTopBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTopBtn from "@/components/items/BaseTopBtn.vue";

export default {
  components: {
    BaseTopBtn,
  },

  data() {
    return {
      article: "",
    };
  },

  created() {
    document
      .querySelector('meta[name="render:status_code"]')
      .setAttribute("content", 404);
    document
      .querySelector('meta[name="robots"]')
      .setAttribute("content", "noindex");

    if (this.$route.name == "NotFound") {
      this.article = "iv2-article";
    }
  },
};
</script>

<style lang="scss">
.iv2-error-404 {
  height: 80vh;
  position: relative;

  .iv2-error-404-block {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .iv2-error-404-number {
      text-align: center;
      padding: 0 2rem;
      font-size: 5.5rem;
    }

    .iv2-error-404-lead {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      padding: 2rem 1rem;
      margin: 0;
    }

    .iv2-error-404-summary {
      text-align: center;
      padding: 0 1rem 2rem 1rem;
      margin: 0;
    }
  }
}

@media (max-width: 1199px) {
  .iv2-error-404 {
    .iv2-error-404-block {
      .iv2-error-404-lead {
        font-size: 1rem;
      }
    }
  }
}
</style>
