<template>
  <div>
    <article class="iv2-article">
      <Error404 v-if="openType === 0" />

      <div v-else-if="openType === 1">
        <BaseTitle :items="items" />
        <LayoutPattern :research="research" />
      </div>
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";

export default {
  components: {
    BaseTitle,
    LayoutPattern,
    BaseLoading,
    Error404,
  },

  mixins: [Meta],

  data() {
    return {
      research: {},
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      openType: "",
      loading: true,
    };
  },

  mounted() {
    const enquetesRepository = this.$repository.get("enquetes");
    const enquetes = enquetesRepository.show();
    const self = this;
    enquetes
      .then((result) => {
        const res = result.data;
        self.research = res;
        self.items.title = res.title;
        self.items.subtitle = res.subtitle;
        if (self.$route.query.mode == "preview") {
          self.openType = 1;
        } else {
          self.openType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
        self.openType = 0;
      })
      .finally(() => {
        self.loading = false;
      });
  },
};
</script>

<style lang="scss"></style>
