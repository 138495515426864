<template>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="column full">
        <div class="spacer height-60"></div>
        <div v-if="explanation">
          <p class="text-pre m-0">{{ explanation }}</p>
          <div class="spacer height-80"></div>
        </div>
      </div>
      <!-- カテゴリーリスト -->
      <div class="column full">
        <div class="iv2-news-archive theme__news-category px-2">
          <div class="row">
            <div class="iv2-news-category d-none d-xl-inline-block col-xl-9">
              <label
                :class="{ active: isActive == 0 }"
                class="iv2-news-category__button"
              >
                <input
                  @click="clickChange(0)"
                  type="radio"
                  value=""
                  name="category"
                  class="iv2-news-category__button-input"
                  autocomplete="off"
                />{{ $store.state.publicLang.news.all_category }}
              </label>
              <label
                v-for="category in categories"
                :key="category.id"
                :class="{ active: isActive == category.id }"
                class="iv2-news-category__button"
              >
                <input
                  type="radio"
                  @click="clickChange(category.id)"
                  :value="category.id"
                  :id="'sort_' + category.id"
                  name="category"
                  class="iv2-news-category__button-input"
                  autocomplete="off"
                />{{ category.name }}
              </label>
            </div>
            <div
              class="iv2-news-category__mobile col-12 d-flex d-xl-none align-items-center justify-content-center"
            >
              <select
                @change="selectChange($event)"
                class="iv2-news-category__select w-100 m-3"
                name="archive"
              >
                <option :selected="isActive == 0" value="0">
                  {{ $store.state.publicLang.news.all_category }}
                </option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  :selected="isActive == category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div
              class="iv2-news-season col-xl-3 d-flex align-items-center justify-content-center"
            >
              <select
                class="iv2-news-season__select m-1 d-none d-xl-block"
                name="archive"
                @change="selectArchive($event)"
              >
                <option value="0" selected>
                  {{ $store.state.publicLang.news.archive }}
                </option>
                <option
                  v-for="archive in archives"
                  :key="archive"
                  :value="archive"
                >
                  {{ archive + $store.state.publicLang.news.year }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="news.length == 0" class="iv2-news__items-null row clearfix">
      <div class="column full">
        <div style="padding: 2rem 0">
          <h4 class="size-24 text-center font-weight-bold">
            {{ $store.state.publicLang.news.null_title }}
          </h4>
          <p class="text-center">
            {{ $store.state.publicLang.news.null_summary }}
          </p>
        </div>
        <div class="spacer height-60"></div>
      </div>
    </div>
    <div v-else class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-60"></div>
        </div>
      </div>

      <div v-if="type == 'list'" class="row clearfix">
        <div class="column full">
          <NewsLine v-for="item in news" :key="item.id" :content="item" />
        </div>
      </div>

      <div v-else-if="type == 'tile'" class="row clearfix">
        <div v-for="item in news" :key="item.id" class="mb-5 col-md-6 col-xl-4">
          <NewsTile :content="item" />
        </div>
      </div>

      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-60"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsLine from "@/components/items/BaseNewsLine.vue";
import NewsTile from "@/components/items/BaseNewsTile.vue";

export default {
  components: {
    NewsLine,
    NewsTile,
  },

  props: {
    news: Array,
    categories: Array,
    archives: Array,
    explanation: String,
    type: String,
  },

  data() {
    return {
      isActive: 0,
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.getCategoryId();
      }
    },
  },

  mounted() {
    this.getCategoryId();
  },

  methods: {
    getCategoryId() {
      if (this.$route.query.category_id) {
        this.isActive = this.$route.query.category_id;
      } else {
        this.isActive = 0;
      }
    },

    clickChange(id) {
      if (this.isActive != id) {
        this.$emit("click-change", id);
      }
    },

    selectChange(event) {
      this.$emit("select-change", event);
    },

    selectArchive(event) {
      this.$emit("select-archive", event);
    },
  },
};
</script>

<style lang="scss">
.iv2-news__items-null {
  padding: 8rem 0;
}

/* PC */
@media (min-width: 1200px) {
  .iv2-news-archive {
    .iv2-news-season__select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-left: 32px;
      border-radius: 0;
      border: 1px solid #000000;
      width: 180px;
      background-color: #ffffff;

      &::-ms-expand {
        display: none;
      }
    }

    .iv2-news-season:before {
      content: "";
      left: 55px;
      display: block;
      position: absolute;
      z-index: 3;
      width: 7px;
      height: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    .iv2-news-category__button {
      display: inline-block;
      background-color: transparent;
      border: none;
      border-bottom: 3px solid transparent;
      margin: 0 15px;
      padding: 15px 0;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer;
      &:hover,
      &.active {
        border-bottom: 3px solid;
      }
      .iv2-news-category__button-input {
        display: none;
        :checked {
          .iv2-news-category__button {
            border-bottom: 3px solid;
          }
        }
      }
      .iv2-news-category__button-name {
        margin: auto;
      }
    }
  }
}
/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-news-archive {
    padding: 10px;
    background-color: transparent !important;

    .iv2-news-category__select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      border: 1px solid #000;
      padding: 0 10px;
      background-color: #fff;
      color: #000;
    }

    .iv2-news-category__mobile:before {
      content: "";
      right: 45px;
      display: block;
      position: absolute;
      z-index: 3;
      width: 7px;
      height: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}
</style>
