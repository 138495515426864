export default {
  mounted() {
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      const findEl = (hash, x) => {
        return (
          document.querySelector(hash) ||
          new Promise((resolve) => {
            if (x > 50) {
              return resolve();
            }
            setTimeout(() => {
              resolve(findEl(hash, ++x || 1));
            }, 500);
          })
        );
      };

      const element = findEl(hash);
      Promise.all([element]).then((value) => {
        return window.scrollTo({
          top: value[0].offsetTop,
          behavior: "smooth",
        });
      });
    }
  },
};
