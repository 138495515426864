<template>
  <span :class="{ 'd-block': type == 'box' }" class="iv2-sns-share__btn">
    <a
      :class="'type-' + type"
      class="iv2-sns-share__btn--link facebook-share"
      :href="baseUrl + href"
      target="blank"
      ><i
        :class="'type-' + type"
        class="iv2-sns-share__btn--icon fab fa-facebook-square"
      ></i>
      <p v-if="type == 'box'" class="iv2-sns-share__btn--text">
        {{ $store.state.publicLang.blog.facebook_share }}
      </p>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "tile",
    },
    href: String,
  },

  data() {
    return {
      baseUrl: "",
    };
  },

  created() {
    this.baseUrl = process.env.VUE_APP_FACEBOOK_SHARE;
  },
};
</script>

<style lang="scss">
.iv2-sns-share__btn {
  .iv2-sns-share__btn--link {
    &.facebook-share {
      background-color: #4167af;
    }
  }
}
</style>
