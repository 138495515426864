<template>
  <transition name="scroll" appear>
    <div
      v-if="scrollY < 100"
      class="iv2-home__scroll-bar position-absolute d-none d-xl-block text-center"
      @click="scroll"
    >
      <span class="iv2-home__scroll-bar-text">{{
        $store.state.themeLang.general.scroll
      }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    scroll() {
      var height = window.innerHeight;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-home__scroll-bar {
  left: 50%;
  bottom: 10px;
  transform: translateX(calc(-50% + 250px / 2));
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  height: 90px;
  cursor: pointer;
  z-index: 111;
  text-shadow: 0px -1px 10px rgba(0, 0, 0, 0.5);
  color: #fff;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateY(calc(-50% + 5px)) translateX(-50%) rotate(45deg);
    -webkit-transform: translateY(calc(-50% + 5px)) translateX(-50%)
      rotate(45deg);
  }

  &::after {
    content: "";
    height: 50px;
    width: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border-left: 1px solid #fff;
  }

  &.scroll-enter-active,
  &.scroll-leave-active {
    transition: all 1s ease;
  }

  &.scroll-enter,
  &.scroll-leave-to {
    transform: translateY(100px) translateX(calc(-50% + 250px / 2)) !important;
    opacity: 0;
  }
}
</style>
