var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row clearfix"},[_vm._m(0),_vm._l((_vm.items),function(item){return _c('div',{key:item.id,class:_vm.indexLayout(_vm.parentItems.index_layout)},[_c('router-link',{staticClass:"iv2-freepage-item__link",attrs:{"to":'/' +
          _vm.parentItems.segment_name +
          '/' +
          item.segment_name +
          _vm.lang +
          _vm.query}},[(_vm.parentItems.index_layout === 'add_thumbnail')?_c('div',{staticClass:"iv2-freepage-item__img fit-img"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":item.thumbnail + _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"m-0",attrs:{"src":item.thumbnail,"alt":'「' +
                item.title +
                '」' +
                _vm.$store.state.publicLang.page.freepage_alt}})])]):_vm._e(),_c('h3',{staticClass:"iv2-freepage-item__title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"iv2-freepage-item__summary"},[_vm._v(_vm._s(item.content))]),_c('p',{staticClass:"iv2-freepage-item__more"},[_c('i',{staticClass:"fas fa-chevron-right"}),_vm._v(" "+_vm._s(_vm.$store.state.publicLang.page.more)+" ")])])],1)}),_vm._m(1)],2)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-60"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-60"})])}]

export { render, staticRenderFns }