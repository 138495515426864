import axios from "axios";

const siteId = document.head.querySelector("[name=sid][content]").content;

const baseURL =
  process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_END_POINT + siteId;

// axiosのインスタンス作成
export default axios.create({
  baseURL,
});
