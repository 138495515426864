<template>
  <span>
    <router-link
      :to="'/' + lang + query"
      class="iv2__top-btn theme__main-btn is-btn is-btn-ghost2 size-18"
      >{{ $store.state.publicLang.general.top_btn }}</router-link
    >
  </span>
</template>

<script>
export default {
  data() {
    return {
      query: "",
      lang: "",
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },
};
</script>

<style lang="scss">
.iv2__top-btn {
  border: 0;
  letter-spacing: 2px;
  max-width: 100%;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
