<template>
  <div
    @click="scrollTop"
    class="iv2-pagetop theme__pagetop d-none d-xl-block text-center"
  >
    <span
      >{{ $store.state.themeLang.general.page_top
      }}<i class="fas fa-chevron-up ml-4"></i
    ></span>
  </div>
</template>

<script>
export default {
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-pagetop {
  padding: 15px 0;
  font-weight: 400;
  cursor: pointer;
}
</style>
