<template>
  <span :class="{ 'd-block': type == 'box' }" class="iv2-sns-share__btn">
    <span
      @click="linkCopy()"
      :class="'type-' + type"
      class="iv2-sns-share__btn--link url-share"
      style="cursor: pointer"
    >
      <i
        v-if="active"
        :class="'type-' + type"
        class="iv2-sns-share__btn--icon fas fa-link"
      ></i>
      <i
        v-else
        :class="'type-' + type"
        class="iv2-sns-share__btn--icon fas fa-check"
      ></i>
      <p v-if="type == 'box'" class="iv2-sns-share__btn--text">
        <span v-if="active">{{ $store.state.publicLang.blog.url_share }}</span>
        <span v-else>{{
          $store.state.publicLang.blog.url_share_complete
        }}</span>
      </p>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "tile",
    },
    href: String,
  },

  data() {
    return {
      active: true,
    };
  },

  methods: {
    linkCopy() {
      const element = document.createElement("input");
      element.value = this.href;
      document.body.appendChild(element);
      element.select();
      document.execCommand("copy");
      document.body.removeChild(element);
      this.active = false;
      setTimeout(() => {
        this.active = true;
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.iv2-sns-share__btn {
  .iv2-sns-share__btn--link {
    &.url-share {
      background-color: #404040;
    }
  }
}
</style>
