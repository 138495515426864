<template>
  <div class="container-fluid" v-html="items.content"></div>
</template>

<script>
export default {
  props: {
    items: Object,
  },
};
</script>

<style lang="scss"></style>
